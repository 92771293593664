<template>
    <div class="h-100 d-flex direction-column">
        <div style="margin:auto 0;">
            <div>
                <div>
                    <template v-for="(tile, index) in tiles">
                        <hr :key="'border_' + index" v-if="index > 0" style="border:none;border-top: 1px solid #DDDDDD;" />
                        <div :key="'tile_' + index" class="py-1">
                            <div class="tile-value">
                                <v-avatar v-if="tile.color && tile.icon" class="mr-2" :color="tile.color" size="30"><v-icon v-if="tile.icon" v-text="'mdi mdi-'+tile.icon"></v-icon></v-avatar>
                                <v-icon class="mr-2" v-else-if="tile.icon" v-text="'mdi mdi-'+tile.icon"></v-icon>
                                <v-avatar v-else-if="tile.color" class="mr-2" :color="tile.color" size="14"></v-avatar>
                                {{ getValue(value[index], tile) }}
                                <v-btn v-if="showSetValueButton() && $eod.getUser().can('requests.create')" @click="showDialog(tile.rawValue)" small
                    icon><v-icon small>mdi-pencil</v-icon></v-btn>
                <template v-if="showSetValueButton() && $eod.getUser().can('requests.read')">
                    <eod-dialog-request-history v-model="historyDialog" :requests="requests"></eod-dialog-request-history>
                </template>
                            </div>
                            <v-menu open-on-hover v-if="(tile.time_interval && getShowField('timestamp')) || (tile.measurement_resolved && getShowField('measurementType')) || (tile.product_id_resolved && getShowField('product')) || (tile.owner_id_resolved && getShowField('ownerName')) || (tile.owner_id_resolved && getShowField('ownerDescription'))">
                                <template v-slot:activator="{on, attrs}">
                                    <v-chip v-on="on" v-bind="attrs" small color="grey lighten-4">
                                        <template v-if="tile.time_interval && getShowField('timestamp')">
                                            <v-icon small left>mdi-clock-outline</v-icon>
                                            <span>{{ $moment(tile.time_interval).format('DD-MM HH:mm')}}</span>
                                        </template>
                                        <template v-else-if="tile.measurement_resolved && getShowField('measurementType')">
                                            <v-icon small left>mdi-waveform</v-icon>
                                            <span>{{ tile.measurement_resolved.name }}</span>
                                        </template>
                                        <template v-else-if="tile.product_id_resolved && getShowField('product')">
                                            <v-icon small left>mdi-package-variant</v-icon>
                                            <span>{{ tile.product_id_resolved.name }}</span>
                                        </template>
                                        <template v-else-if="tile.owner_id_resolved && getShowField('ownerName')">
                                            <v-icon small left>mdi-account-outline</v-icon>
                                            <span>{{ getUserName(tile.owner_id_resolved) }}</span>
                                        </template>
                                        <template v-else-if="tile.owner_id_resolved && getShowField('ownerDescription')">
                                            <v-icon small left>mdi-account-outline</v-icon>
                                            <span>{{ tile.owner_id_resolved.attributes.description[0] }}</span>
                                        </template>
                                    </v-chip>
                                </template>
                                <v-card>
                                    <v-card-text>
                                    <div v-if="tile.time_interval && getShowField('timestamp')"><v-icon small left>mdi-clock-outline</v-icon> {{ $moment(tile.time_interval).format('DD-MM HH:mm') }}</div>
                                    <div v-if="tile.measurement_resolved && getShowField('measurementType')"><v-icon small left>mdi-waveform</v-icon> {{ tile.measurement_resolved.name }}</div>
                                    <div v-if="tile.product_id_resolved && getShowField('product')"><v-icon small left>mdi-package-variant</v-icon> {{ tile.product_id_resolved.name }}</div>
                                    <div v-if="tile.owner_id_resolved && getShowField('ownerName')"><v-icon small left>mdi-account-outline</v-icon> {{ getUserName(tile.owner_id_resolved) }}</div>
                                    <div v-if="tile.owner_id_resolved && tile.owner_id_resolved.attributes && tile.owner_id_resolved.attributes.description && tile.owner_id_resolved.attributes.description[0] && getShowField('ownerDescription')"><v-icon small left>mdi-account-outline</v-icon> {{ tile.owner_id_resolved.attributes.description[0] }}</div>
                                </v-card-text>
                                </v-card>
                            </v-menu>
                        </div>
                    </template>
                </div>
                <eod-dialog-request :defaultValue="newValue" :settings="getRequestSettings(cell.settings)" v-if="showSetValueButton() && showSetValueDialog" v-model="showSetValueDialog"></eod-dialog-request>
            </div>
            <!--<div style="width:200px;height:60%" v-if="tiles.length == 1">
                <eod-graph-line v-if="tiles[0]" ref="chart" group="eod-graph-tile" :id="graphId" :options="options"
                    :defaultSerie="serie" :value="graphValue" :template="template"></eod-graph-line>
            </div>-->
            <div v-if="!tiles[0]">Geen resultaten gevonden.</div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.tile-value {
    font-size: 1.4em;
    padding: 5px;
}
</style>
<script>
import eodGraphLine from './eod-graph-line.vue';
import eodObjectAutocomplete from './../eod-object-autocomplete.vue';
import eodObjectsTreeviewInput from './../eod-objects-treeview-input.vue';
import eodUserSelector from './../eod-user-selector.vue';
import { v4 as uuidv4 } from 'uuid';
import Request from './../../models/request';
import User from './../../models/user';
import cellRequest from './../dashboard/mixins/cellRequest';
import eodDialogRequestHistory from './eod-dialog-request-history.vue';
import eodDialogRequest from './eod-dialog-request.vue';

export default {
    name: 'eod-graph-tile',
    components: {
        eodGraphLine,
        eodObjectAutocomplete,
        eodObjectsTreeviewInput,
        eodUserSelector,
        eodDialogRequestHistory,
        eodDialogRequest
    },
    mixins:[cellRequest],
    props: {
        id: String,
        value: Object | Array,
        template: Object | Array,
        cell: Object | Array,
    },
    data: function () {
        return {
            graphId: uuidv4(),
            tiles: [],
            query: null,
            graphValue: [],
            settings: require('./../../classes/settings'),
            options: {
                color: ['#cccccc'],
                toolbox: {
                    show: false,
                },
                tooltip: {
                    show: false
                },
                grid: {
                    top: '1px',
                    left: '1px',
                    right: '1px',
                    bottom: '1px',
                    containLabel: false,
                    borderWidth: 0,
                },
                xAxis: {
                    type: 'time',
                    show: false
                },
                yAxis: {
                    show: false
                },
            },
            serie: {
                type: 'line',
                smooth: true,
                lineStyle: {
                    width: 2,
                },
                data: [],
                showSymbol: false,
            }
        }
    },
    methods: {
        getShowField(fieldName){
            if(!this.cell){
                return true;
            }

            if(!this.cell.settings){
                return true;
            }

            if(!this.cell.settings.fields){
                return true;
            }

            if(this.cell.settings.fields.includes(fieldName)){
                return true;
            }

            return false;
        },
        getUserName(usr){
            return (new User(usr)).getFullName();
        },
        getValue(serie, tile) {

            if(!serie){
                return '-';
            }

            let val = '';

            if(this.cell){
                const settings = this.cell.settings.queries[serie.queryIndex];
                if (settings && settings.view == 'timestamp') {
                    val += this.$moment(tile.time_interval).locale('nl').fromNow();
                } else {
                    val += tile.value;
                    if (!this.hasListItem(serie, tile) && tile.unit && tile.unit.Symbol) {
                        val += ' '+tile.unit.Symbol;
                    }
                }
            }else{
                val += tile.value;
                if (!this.hasListItem(serie, tile) && tile.unit && tile.unit.Symbol) {
                    val += ' '+tile.unit.Symbol;
                }
            }

            

            return val;
        },
        resize() {
            if (this.$refs.chart) {
                this.$refs.chart.resize();
            }
        },
        async update() {

            if(!this.template[0]){
                return false;
            }

            this.tiles = [];

            if (this.template[0]) {
                if (this.showSetValueButton()) {
                    this.requests = [];

                    const measurementType = await this.getMeasurementType();

                    await this.getRequests(5).then(response => {
                        if (response.data.data.requests && response.data.data.requests.edges[0]) {
                            const requests = response.data.data.requests.edges;
                            for (let i = 0; i < requests.length; i++) {
                                let request = requests[i];

                                if (measurementType.valueItems) {
                                    if (measurementType.valueItems[request.value]) {
                                        request.value = measurementType.valueItems[request.value].name;
                                    }
                                }

                                this.requests.push(new Request(request));
                            }
                        }
                    });
                }
            }

            if(this.value){
                for (let i = 0; i < this.value.length; i++) {
                    let tile = {};
                    const serie = this.value[i];
                    let val = null;
                    for (let i = 0; i < serie.data.length; i++) {
                        val = serie.data[i];
                        if (val) {
                            tile = val;
                            break;
                        }
                    }
                    
                    tile.rawValue = this.getTileRawValue(serie, tile);
                    tile.value = this.getTileValue(serie, tile);
                    tile.color = this.getTileColor(serie, tile);
                    tile.icon = this.getTileIcon(serie);
                    tile.unit = this.getTileUnit(serie, tile);
                    this.tiles.push(tile);
                }
            }
            
            if(this.tiles.length == 0){
                this.tiles.push({
                    value: null,
                });
            }

            this.$nextTick(() => {
                if (this.$refs.chart) {
                    this.setGraphValue();
                } else {
                    this.$emit('finished', null, null);
                }
                this.$forceUpdate();
            });

        },
        getTileIcon(serie) {
            for (const key in serie.measurementTypes) {
                let measurementType = serie.measurementTypes[key];
                if (measurementType.icon) {
                    return measurementType.icon;
                }
            }

            return null;
        },
        getTileColor(serie, tile) {

            if (tile && this.template[0]) {
                const content = this.template[0].content;
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    const measurementType = serie.measurementTypes[aggMeasurementType.object.id];
                    let value = parseInt(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]);
                    if (measurementType.valueItems && measurementType.valueItems[value]) {
                        return measurementType.valueItems[value].color;
                    }
                }
            }

            return null;
        },
        getTileValue(serie, tile) {
            if (tile && this.template[0]) {
                const content = this.template[0].content;
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    const measurementType = serie.measurementTypes[aggMeasurementType.object.id];
                    if(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]){
                        let value = parseFloat(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]);
                        if (measurementType.valueItems && measurementType.valueItems[value]) {
                            return measurementType.valueItems[value].name;
                        }

                        return value;
                    }
                }
            }

            return '-';
        },
        getTileRawValue(serie, tile) {
            if (tile && this.template[0]) {
                const content = this.template[0].content;
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    const measurementType = serie.measurementTypes[aggMeasurementType.object.id];
                    if(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]){
                        let value = parseFloat(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]);
                        return value;
                    }
                }
            }

            return null;
        },
        hasListItem(serie, tile) {
            if (tile && this.template[0]) {
                const content = this.template[0].content;
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    const measurementType = serie.measurementTypes[aggMeasurementType.object.id];
                    let value = parseInt(tile[aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id]);
                    if (measurementType.valueItems && measurementType.valueItems[value]) {
                        return true;
                    }
                }
            }

            return false;
        },
        getTileUnit(serie, tile) {

            if (tile && this.template[0]) {
                const content = this.template[0].content;
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    if(aggMeasurementType.aggregation == 'count'){
                        return null;
                    }
                }
            }

            for (const key in serie.measurementTypes) {
                let measurementType = serie.measurementTypes[key];
                return { ...measurementType.unit, ...measurementType.unitFields };
            }

            return null;
        },
        setGraphValue() {

            // Only first query
            let query = Object.assign([], this.value[0]);

            //Only show last 20 results
            let data = [];

            let start = query.data.length >= 20 ? query.data.length - 20 : 0;
            for (let i = start; i < query.data.length; i++) {
                let value = query.data[i];
                data.push(value);
            }

            query.data = data;

            this.graphValue = [query];

            this.$nextTick(() => {
                this.$refs.chart.update();
                this.$emit('finished', this.graphId, null);
            });
        },
    }
}
</script>