<template>
    <eod-dialog title="Waarde instellen" icon="mdi-pencil-outline" :value="value" @input="val => $emit('input', val)" iconColor="primary" max-width="600">
                        <v-form ref="formNewValue" class="mt-4">
                            <eod-object-autocomplete v-if="!settings.project_id" class="mb-4"
                                label="Project" endpoint="projects" v-model="newValue.projectId"></eod-object-autocomplete>
                            <eod-object-autocomplete v-if="!settings.job_type_id" class="mb-4" label="Jobtype"
                                endpoint="jobTypes" v-model="newValue.jobTypeId"></eod-object-autocomplete>
                            <eod-objects-treeview-input v-if="!settings.product_id" class="mb-4" label="Toestel" :selectable="['DEVICE']" v-model="newValue.productId"></eod-objects-treeview-input>
                            <eod-user-selector v-if="!settings.owner_id" class="mb-4" label="Eigenaar" dense
                                v-model="newValue.owner_id"></eod-user-selector>
                            <eod-text-field label="Waarde" :rules="[val => $validator.required(val), rules.range]" v-if="isRange(newValue.measurementType)" :min="getRange(newValue.measurementType).low" :max="getRange(newValue.measurementType).high" :step="getRange(newValue.measurementType).stepSize" type="number" required v-model="newValue.value"></eod-text-field>
                            <template v-else-if="isToggle(newValue.measurementType)">
                                <v-btn-toggle rounded color="primary" v-model="newValue.value">
                                    <v-btn v-for="listItem in Object.values(newValue.measurementType.valueItems)" :key="listItem.id" :value="listItem.threshold">
                                       {{listItem.name}}
                                    </v-btn>
                                </v-btn-toggle>
                            </template>
                            <v-autocomplete dense label="Waarde"
                                v-else-if="isList(newValue.measurementType)"
                                v-model="newValue.value" :items="Object.values(newValue.measurementType.valueItems)"
                                item-value="threshold" item-text="name" outlined hide-details></v-autocomplete>
                            <div v-else class="d-flex">
                                <eod-text-field label="Waarde" v-model="newValue.value"
                                    type="number" step="0.01" :rules="[val => $validator.required(val), val => $validator.min(val, getMinValue(newValue)), val => $validator.max(val, getMaxValue(newValue))]"></eod-text-field>
                                <div class="grey lighten-3 px-4 d-flex justify-content-center align-items-center"
                                    v-if="newValue.measurementType && newValue.measurementType.unitFields && newValue.measurementType.unitFields.Symbol">
                                    {{ newValue.measurementType.unitFields.Symbol }}</div>
                            </div>
                    </v-form>
                    <template v-slot:actions>
                        
                            <v-btn rounded text @click="$emit('input', false)">Annuleren</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn :loading="setValueLoading"
                                :disabled="!(newValue.value != null && newValue.value !== '' && newValue.jobTypeId && newValue.productId && newValue.ownerId && newValue.projectId)"
                                class="pl-4" rounded depressed color="secondary" @click="sendValue">Instellen <v-icon small
                                    right>mdi-pencil</v-icon></v-btn>
                    </template>
                </eod-dialog>
</template>
<script>
import eodObjectAutocomplete from './../eod-object-autocomplete.vue';
import eodUserSelector from './../eod-user-selector.vue';
import eodObjectsTreeviewInput from './../eod-objects-treeview-input.vue';
export default {
    name: 'eod-dialog-request',
    components:{
        eodObjectAutocomplete,
        eodUserSelector,
        eodObjectsTreeviewInput,
    },
    props: {
        value: Boolean,
        settings: Object,
        defaultValue: Object
    },
    data: () => ({
        rules: {
                range: v => {
                    const range = this.getRange(this.newValue.measurementType);
                    const number = parseFloat(v);
                    if(number >= range.low && number <= range.high && number % range.stepSize == 0){
                        return true;
                    }
                    return 'Waarde moet tussen '+range.low+' en '+ range.high+' liggen met stappen van '+range.stepSize+'!';
                }
            },
        newValue: {},
        setValueLoading: false,
    }),
    created(){
        if(this.defaultValue){
            this.newValue = this.defaultValue;

            // When toggle button, select current value
            if(!this.isToggle(this.newValue.measurementType)){
                this.newValue.value = null;
            }
        }
    },
    methods:{
        isToggle(measurementType){
            if(measurementType && measurementType.valueItems && Object.values(measurementType.valueItems).length == 2){
                for (let i = 0; i < Object.values(measurementType.valueItems).length; i++) {
                    const listItem = Object.values(measurementType.valueItems)[i];
                    // If name is too long, show in dropdown
                    if(listItem.name.length > 30){
                        return false;
                    }
                }
                return true;
            }

            return false;
        },
        isList(measurementType){
            if(measurementType && measurementType.valueItems && Object.keys(measurementType.valueItems).length > 0){
                return true;
            }

            return false;
        },
        getRange(measurementType){
            const firstListItem = Object.values(measurementType.valueItems)[0];
            return firstListItem;
        },
        isRange(measurementType){
            if(measurementType && measurementType.valueConfigList && measurementType.valueConfigList.itemType == 'RANGE' && measurementType.valueItems && Object.keys(measurementType.valueItems).length > 0){
                return true;
            }

            return false;
        },
        getMinValue(newVal){
            if(this.cell && this.cell.settings && this.cell.settings.request && this.cell.settings.request.min != '' && !isNaN(this.cell.settings.request.min)){
                return this.cell.settings.request.min;
            }

            if(newVal.measurementType && newVal.measurementType.typeConfig && newVal.measurementType.typeConfig.min != null){
                return newVal.measurementType.typeConfig.min;
            }

            return null;
        },
        getMaxValue(newVal){
            if(this.cell && this.cell.settings && this.cell.settings.request && this.cell.settings.request.max != '' && !isNaN(this.cell.settings.request.max)){
                return this.cell.settings.request.max;
            }

            if(newVal.measurementType && newVal.measurementType.typeConfig && newVal.measurementType.typeConfig.max != null){
                return newVal.measurementType.typeConfig.max;
            }

            return null;
        },
        sendValue() {
            if(!this.$refs.formNewValue){
                this.$toaster.notify({
                        type: 'danger',
                        title: this.$t('Error'),
                        body: this.$t('Fout bij het versturen van de nieuwe waarde')
                    });
                return false;
            }

            if(!this.$refs.formNewValue.validate()){
                this.$toaster.notify({
                        type: 'danger',
                        title: this.$t('Error'),
                        body: this.$t('Ongeldige waarde!')
                    });
                    return false;
            }

            this.setValueLoading = true;

            let requestData = {
                jobTypeId: this.newValue.jobTypeId,
                ownerId: this.newValue.ownerId,
                projectId: this.newValue.projectId,
                userId: this.$eod.getUser().id,
                statusCode: 'to_do',
                timestamp: this.$moment().unix(),
                measurementTypeId: this.newValue.measurementType.id,
                value: '' + this.newValue.value,
                productId: this.newValue.productId,
                type: 'DCMD'
            }

            this.$eod.save('Request', requestData).then(response => {
                if (response.data.data && response.data.data.createRequest) {
                    this.$toaster.notify({
                        type: 'success',
                        title: this.$t('Succes'),
                        body: this.$t('De nieuwe waarde wordt verstuurd!')
                    });

                    this.$emit('reload');

                    setTimeout(() => {
                        this.$emit('reload');
                    }, 5000);
                } else {
                    this.$toaster.notify({
                        type: 'danger',
                        title: this.$t('Error'),
                        body: this.$t('Fout bij het versturen van de nieuwe waarde')
                    });
                }
            }).catch(() => {
                this.$toaster.notify({
                    type: 'danger',
                    title: this.$t('Error'),
                    body: this.$t('Fout bij het versturen van de nieuwe waarde')
                });
            }).finally(() => {
                this.setValueLoading = false;
                this.$emit('input', false)
            })
        },
    }
}
</script>